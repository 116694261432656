<template>
  <div class="error-message">
    <span class="text" :style="`--color-error:${color}`">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "ErrorMessage",
  props: {
    text: { type: String, required: true },
    color: { type: String, default: "#ff5252", required: false },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  .text {
    color: var(--color-error) !important;
    caret-color: var(--color-error) !important;
    line-height: 14px;
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-size: 12px;
    font-weight: 400;
  }
}
</style>